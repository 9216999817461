
const Page_Pricing = () => {
   

    return(
        <>
            <h1 className="text-2xl font-extrabold dark:text-white">Terms of Use</h1>
            <p>
                By accessing and using this website, you agree to be bound by the terms and conditions outlined below.
            </p>
            <br/>
            <h2 className="text-1xl font-bold dark:text-white">Disclaimer</h2>
            <p>
                The services are provided "as is" .
            </p>
            <br/>
            <h2 className="text-1xl font-bold dark:text-white">Limitation of Liability</h2>
            <p>
                This site's owner shall not be liable for any damages of any kind arising from the use of this site, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.
            </p>
        </>
    );
}

export default Page_Pricing;