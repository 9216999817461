declare global {
    interface Window { MyConfig: any; }
}

window.MyConfig = window.MyConfig || {};

// the above comes from
// //https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript

//https://www.youtube.com/watch?v=eGcosoC43vI

interface IConfig {
    backEndApi:string
}

const config:IConfig = window.MyConfig;

export default config;