import {useState} from 'react';

const Page_Pricing = () => {
    

    return(
        <div>
            Ionscreen is currently in beta-phase. Please <a href="/contact" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">contact us</a> if you want to start using it.
            <br/>Pricing details are still being determined. Come back to this page soon.
            <div className="grid gap-4 grid-cols-1 md:grid-cols-4 items-center mt-5">
                <div className="w-50 bg-slate-200 rounded-lg grid-rows-3 h-64 " >
                    <div className="bg-blue-300 rounded-lg h-10 mt-2 ml-2 mr-2 font-bold text-center">Beta-tester</div>
                    <div className='text-center'>4.99€ /month</div>
                    <div className='text-center'>Restrictions apply</div>
                </div>
                <div className="w-50 bg-slate-200 rounded-lg grid-rows-3 h-64 " >
                    <div className="bg-blue-300 rounded-lg h-10 mt-2 ml-2 mr-2 font-bold text-center">Pricing 2</div>
                    <div className='text-center'>tbd€ /month</div>
                    <div className='text-center'>-</div>
                </div>
                <div className="w-50 bg-slate-200 rounded-lg grid-rows-3 h-64 " >
                    <div className="bg-blue-300 rounded-lg h-10 mt-2 ml-2 mr-2 font-bold text-center">Pricing 3</div>
                    <div className='text-center'>tbd€ /month</div>
                    <div className='text-center'>-</div>
                </div>
                <div className="w-50 bg-slate-200 rounded-lg grid-rows-3 h-64 " >
                    <div className="bg-blue-300 rounded-lg h-10 mt-2 ml-2 mr-2 font-bold text-center">Pricing 4</div>
                    <div className='text-center'>tbd€ /month</div>
                    <div className='text-center'>-</div>
                </div>
                
    
            </div>
        </div>
    );
}

export default Page_Pricing;