import {useState} from 'react';
import ionscreenImage from './IonScreen.png';

const Page_Index = () => {
    
    return(
        <div>
          <div className="grid grid-cols-1 space-y-7 space-x-7 md:grid-cols-2" >
            <div className='grid space-y-7 order-last md:order-first text-sm'>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                Securely display your online dashboards and other webcontent using a small streaming device.
                </div>
              </div>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                Great way to get started with your digital signage.
                </div>
              </div>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>The streaming device contains no logons/passwords. 
                </div>
                </div>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                  Multiple streaming devices are supported (eg one at the reception, one in the IT support room, ..). Each device can have its own content.
                  </div>
                  </div>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                  Refresh rates for the dashboards up to every 15 minutes. The images of the dashboards are generated in our secured compute environment.
                  </div>
                  </div>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                  Content shown is managed using our online management portal.
                  </div>
                  </div>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                  The streaming device can easily be disconnected from the online content using the management portal (eg in case of theft).
                  </div>
              </div>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                Simply power up the monitor and the streaming device. The dashboard application will start automatically, no extra actions are required.
                </div>
              </div>
              <div  className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                Existing supported dashboards:
                <ul className='list-disc ml-8'>
                  <li>Microsoft Crm dashboards</li>
                  <li>Microsoft Azure dashboards</li>
                  <li>Linkedin company posts</li>
                  <li>Zabbix</li>
                  <li>SharePoint documents</li>
                  <li>...more connectors coming up. Feel free to make <a href="/contact" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">suggestions</a>.</li>
                </ul>
              </div>
              </div>
              <div className='bg-slate-200 rounded-lg'>
                <div className='ml-2'>
                  Want to get started? Contact us using our <a href="/contact" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">contact page</a>.
                  </div>
                  </div>
            </div>
            <div className='rounded-lg'><img src={ionscreenImage} alt='Welcome image' className='.object-scale-down h-70 w-85 rounded-lg mb-7' /></div>
          </div>
        </div>
    );
}

export default Page_Index;