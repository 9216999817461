import {FormEvent, useState} from 'react';
import axios from 'axios';
import config from '../Config';


const Page_Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const onSubmit = async (element:FormEvent ) => {

        element.preventDefault(); // prevent page refresh

        var payload:any = {
            name: name,
            email: email,
            message : message
        };
        try {
         await axios.post(config.backEndApi + `/api/Contact`, payload);
         setName('');
         setEmail('');
         setMessage('');
         let dateTime = new Date().toLocaleTimeString();
         setStatus(`${dateTime} : Your message was sent successfully. We will contact you shortly.`);
        }
        catch {
            let dateTime = new Date().toLocaleTimeString();
            setStatus(`${dateTime} : There was a problem sending your message. Please try again.`);
        }
    } 

    return(
        <div className="grid grid-cols-1 md:grid-cols-2 space-y-7" >
            <div className="text-2xl font-bold">Want to know more?</div>
            <div>Contact us using the form below:
                <form onSubmit={onSubmit} className="p-10 bg-white rounded-lg drop-shadow-lg space-y-4 mt-10" action="">
                    <div className="flex flex-col">
                        <label htmlFor='name'>Name</label>
                        <input type='text' name='name' id='name' required onChange={event => setName(event.target.value)} value = {name} className="peer border border-slate-400"/>

                        <p className="invisible peer-invalid:visible text-red-700 font-light">
                            Please enter your name
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" required onChange={event => setEmail(event.target.value)} value = {email} className="peer border border-slate-400"/>
                        <p className="invisible peer-invalid:visible text-red-700 font-light">
                            Please enter a valid email address
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows={15} maxLength={1024} required onChange={event => setMessage(event.target.value)} value ={message} 
                            className="peer border border-slate-400"></textarea>
                        <p className="invisible peer-invalid:visible text-red-700 font-light">
                            This field cannot be empty
                        </p>
                    </div> 
                    <button type="submit" className="px-5 py-1 bg-blue-300 rounded-lg">Send</button>
                    <div>{status}</div>
                    <div className = "text-slate-400 text-xs"><div className="font-bold">Privacy policy:</div>Your contact information will only be used to contact you regarding your message.</div>
                </form>
            </div>
        </div>
    );
}

export default Page_Contact;