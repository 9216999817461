import {useState, useEffect} from 'react';

const Footer = () => {

    const [year, setYear] = useState('');

    useEffect(() => {
        // Run! Like go get some data from an API.
        setYear(`${new Date().getFullYear()}`);
      }, []);

    return(
        <div>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <footer> <small>&copy; Copyright {year} IonScreen</small> </footer> 
        </div>
    );
}

export default Footer;