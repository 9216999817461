import React from 'react';
import logo from './logo.svg';
import './App.css';
import HamburgerMenu from './Components/HamburgerMenu';
import { BrowserRouter , Routes,  Route, Link } from "react-router-dom";
import Page_Index from './Components/Page_Index';
import Page_Contact from './Components/Page_Contact';
import Page_Pricing from './Components/Page_Pricing';
import Footer from './Components/Footer';
import Page_Terms from './Components/Page_Terms';


    

function App() {
  return (
    <div className="container mx-auto mb-8 px-8 max-w-screen-lg">
       <div className="">
        <HamburgerMenu/>
        <div className="mt-10">
          <BrowserRouter>
          <Routes>
            <Route path="/"  element={<Page_Index/>} />
            <Route path="/pricing" element={<Page_Pricing/>} />
            <Route path="/contact" element={<Page_Contact/>} />
            <Route path="/terms" element={<Page_Terms/>} />
          </Routes>
          </BrowserRouter>
        </div>
      </div>
    <Footer/>
    </div>
  );
}

export default App;
